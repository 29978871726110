import React, { useState } from "react";
// import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Card,
  Button
} from 'react-bootstrap';
import { Icon } from '@iconify/react';
import DataTable from "react-data-table-component";
import { Link, useNavigate} from 'react-router-dom';
import avatar1 from '../assets/img/userport.png'
import avatar2 from '../assets/img/avatars/2.png'
import avatar3 from '../assets/img/avatars/3.png'
import avatar4 from '../assets/img/avatars/4.png'
import avatar5 from '../assets/img/avatars/5.png'
import { AuthPostdelete, AuthgetApi } from "../axiosConfig/promise";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Image_endpoint } from "../../local";
import toast, { Toaster } from "react-hot-toast";
export default function Users() {
  const [search,settext]=useState('')
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  let url = "userList?searchText="+search
  const { data:userlisting, error, isLoading } = useQuery({
    queryKey: ['userListing', url],
    queryFn: () => AuthgetApi(url),
    staleTime: Infinity,
  });


  const [searchText, setSearchText] = useState('');

  
  const mutationdelete  = useMutation({
    mutationFn:(params) => AuthPostdelete(params.url),
    onSuccess: (data) => {
    // Store the token and fetch user details
    if(data.status){
      toast.success('User deleted successfully!')
      queryClient.invalidateQueries('userListing'); // Invalidate and refetch admin data if you have a query for it
      // setErrors({})
    //   setShow(false)
    // handleClose()
      return;
    }

    toast.success(data?.message)
return
    // localStorage.setItem('token', data.token);
    // Optionally, refetch user details or navigate to a different page
  },
  onError: (error) => {
    console.error(' error:', error.message);
    toast.error(error.message)

    // Optionally, display an error message to the user
  },
  onSettled: (data, error) => {
    // Perform cleanup tasks or UI updates after mutation completes
  },
  retry: 3, // Retry failed mutations up to 3 times
  retryDelay: 1000, // Delay between retry attempts (in milliseconds)
  mutationKey: 'notficationlist', // Unique key for the mutation
  onErrorThrow: true, // Throw error for failed mutations
});

  const handleDelete = (userId) => {
    let parms = {
        url:"deleteUser?userId="+userId,
      }
    mutationdelete.mutate(parms);
};
  const columns = [
    {
      name: 'Name',
      selector: row => row.fullName,
      sortable: true,
      cell: (row) => 
      <>
        <img loading="lazy" className="table_img rounded-circle img-thumbnail me-2" src={row.profilePic==null?avatar1:Image_endpoint+row.profilePic} />
        {row.fullName}
      </>      
    },
    {
      name: 'Email',
      selector: row => row.email,
      sortable: true,
      cell: (row) => row.email
    },
    {
      name: 'Phone No.',
      selector: row => row.phoneNumber,
      sortable: true,
      cell: (row) => `+${row.countryCode}-${row.phoneNumber}`
    },
    // {
    //   name: 'Status',
    //   selector: row => row?.Status,
    //   sortable: true,
    //   cell: (row) => <span className={row.status + ' badge' }  >{row.status}</span>
    // },
    {
      name: 'Actions',
      cell: row => (
        <>
          <Link className="btnview" to={`/Viewuser?id=${row?._id}`}>
            <Icon icon="tabler:eye"></Icon>
          </Link>
          <Link className="btnedit" to={`/Edituser?id=${row?._id}`} >
            <Icon icon="tabler:pencil"></Icon>
          </Link>
          <Link className="btndelete"  onClick={() => handleDelete(row._id)}>
            <Icon icon="tabler:trash"></Icon>
          </Link>
        </>
      ),
    },
  ];
  // const [data, setData] = useState([
  //   { 
  //     id: 1, 
  //     name: 'Maria James', 
  //     imgurl: avatar1,
  //     email: 'maris@gmail.com', 
  //     phone: '0123-21-323', 
  //     status: 'Active', 
  //   },
  //   { 
  //     id: 2, 
  //     name: 'Johnson Charles', 
  //     imgurl: avatar2,
  //     email: 'johson@gmail.com', 
  //     phone: '0123-21-323', 
  //     status: 'Inactive', 
  //   },
  //   { 
  //     id: 3, 
  //     name: 'David Smith', 
  //     imgurl: avatar3,
  //     email: 'david@gmail.com', 
  //     phone: '0123-21-323', 
  //     status: 'Active', 
  //   },
  //   { 
  //     id: 4, 
  //     name: 'Steve Jones', 
  //     imgurl: avatar4,
  //     email: 'steve@gmail.com', 
  //     phone: '0123-21-323', 
  //     status: 'Inactive', 
  //   },
  //   { 
  //     id: 5, 
  //     name: 'James Vince', 
  //     imgurl: avatar5,
  //     email: 'jamese@gmail.com', 
  //     phone: '0123-21-323', 
  //     status: 'Active', 
  //   },
  // ]);

  // Filter data based on search text
  const filteredData = userlisting?.data?.list?.filter(item =>
    item.fullName.toLowerCase().includes(searchText.toLowerCase()) ||
    item.email.toLowerCase().includes(searchText.toLowerCase()) 
    // item.phoneNumber.toLowerCase().includes(searchText.toLowerCase()),
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error?.message}</div>;

  return (
    <React.Fragment>
      <Row>
        <Col lg={12}>
          <Card className="mt-4">
            <Card.Header className="d-flex align-items-center justify-content-between">
              <h5>Users</h5>
              <Button className="btn btn-primary px-4 min_width140" as={Link} to="/Adduser">Add</Button>
            </Card.Header>
            <Card.Body>
              <div className="text-end mb-3">
                <input
                  type="text"
                  placeholder="Search..."
                  className="searchfield"
                  value={searchText}
                  onChange={e => setSearchText(e.target.value)}
                />
              </div>
              <DataTable
                columns={columns}
                data={filteredData}
                pagination
                responsive
                className="custom-table"
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Toaster/>
    </React.Fragment>
  );
}

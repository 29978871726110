// export const REACT_APP_API_URL="http://174.129.5.247:3005/v1/api/"
export const REACT_APP_API_URL = "https://adaap.ae/v1/api/";
// export const REACT_APP_API_URL = "http://localhost:3005/v1/api/";
// 192.168.1.10
// export const REACT_APP_API_URL="http://192.168.1.19:3005/v1/api/" // local endpoint
// export const REACT_APP_API_URL="http://192.168.1.8:3005/v1/api/"

// export const Image_endpoint = "https://adaapbackendd.s3.amazonaws.com/"
export const Image_endpoint = "https://adaappbackend.s3.amazonaws.com/";
export const GOOGLE_API_KEY = "AIzaSyBHQWCrTWJIMYz04ZN43VyZ9xv6agXnuyk";

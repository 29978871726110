import React, { useEffect, useState } from "react";
import { Modal, Card, Form, Button } from "react-bootstrap";
import { Icon } from "@iconify/react";
import DataTable from "react-data-table-component";
import { Link } from "react-router-dom";
import restaurantimg from "../assets/img/restaurantimg.jpg";
import homebusinessimg from "../assets/img/homebusinessimg.jpg";
import avatar1 from "../assets/img/userport.png";
import companyimg from "../assets/img/companyimg.jpg";
import shopimg from "../assets/img/shopimg.jpg";
import {
  AuthPost,
  AuthPostdelete,
  AuthgetApi,
  authFormdataPost,
} from "../axiosConfig/promise";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Image_endpoint, REACT_APP_API_URL } from "../../local";
import moment from "moment";
import toast, { Toaster } from "react-hot-toast";
import catgSchema from "../formValidation/catgSchema";
import { authInstance } from "../axiosConfig/configuraton";
import { ThreeCircles } from "react-loader-spinner";

export default function Category() {
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const [form, setForm] = useState({ categoryPic: "" });
  const [editForm, setEditForm] = useState({});
  const [selectedCategoryId, setSelectedCategoryId] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();

  const url = `fetchBusinessCategory`;
  const {
    data: categorylist,
    error,
    isLoading,
  } = useQuery({
    queryKey: ["categorylist", url],
    queryFn: () => AuthgetApi(url),
    staleTime: Infinity,
  });

  useEffect(() => {
    console.log(form, "checking here ");
  }, [form]);

  const handleClose = () => {
    setShow(false);
    setForm({});
  };
  const handleShow = () => setShow(true);
  const handleClose2 = () => setShow2(false);
  const handleShow2 = (category) => {
    setEditForm({
      category: category.category,
      categoryPic: category.categoryPic,
    });
    setSelectedCategoryId(category._id);
    setShow2(true);
  };

  const convertToFormData = (form) => {
    const formData = new FormData();
    for (const key in form) {
      formData.append(key, form[key]);
    }
    return formData;
  };

  const mutationdelete = useMutation({
    mutationFn: (params) => AuthPostdelete(params.url),
    onSuccess: (data) => {
      // Store the token and fetch user details
      if (data.status) {
        toast.success("Notification Sended successfully!");
        queryClient.invalidateQueries("notficationlist"); // Invalidate and refetch admin data if you have a query for it
        setErrors({});
        //   setShow(false)
        handleClose();
        return;
      }

      toast.success(data?.message);
      return;
      // localStorage.setItem('token', data.token);
      // Optionally, refetch user details or navigate to a different page
    },
    onError: (error) => {
      console.error(" error:", error.message);
      toast.error(error.message);

      // Optionally, display an error message to the user
    },
    onSettled: (data, error) => {
      // Perform cleanup tasks or UI updates after mutation completes
    },
    retry: 3, // Retry failed mutations up to 3 times
    retryDelay: 1000, // Delay between retry attempts (in milliseconds)
    mutationKey: "notficationlist", // Unique key for the mutation
    onErrorThrow: true, // Throw error for failed mutations
  });

  const mutation = useMutation({
    mutationFn: authFormdataPost,
    onSuccess: (data) => {
      if (data.status) {
        toast.success(data?.message);
        queryClient.invalidateQueries("categorylist");
        setErrors({});
        handleClose();
        handleClose2();
        return;
      }
      setForm({});
      toast.success(data?.message);
    },
    onError: (error) => {
      console.error(" error:", error.message);
      toast.error(error.message);
    },
    retry: 3,
    retryDelay: 1000,
    mutationKey: "categorylist",
    onErrorThrow: true,
  });

  const validate = (formData) => {
    const { error } = catgSchema.validate(formData, { abortEarly: false });
    if (!error) return {};
    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formErrors = validate(form);
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      const parms = {
        url: selectedCategoryId
          ? "updateBusinessCategory"
          : "createBusinessCategory",
        formdata: convertToFormData(form),
      };
      mutation.mutate(parms);
    }
  };
  const removeImage = (e) => {
    e.preventDefault();
    setForm((prev) => ({
      ...prev,
      categoryPic: "",
    }));
    document.getElementById("categoryimg").value = null;
    return;
  };
  const handleEditSubmit = (e) => {
    e.preventDefault();
    const formErrors = validate(editForm);
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      const parms = {
        url: "updateBusinessCategory",
        formdata: convertToFormData({ ...editForm, id: selectedCategoryId }),
      };
      mutation.mutate(parms);
    }
  };

  const handleDelete = (categoryId) => {
    let parms = {
      url: "adminDeleteCategory?categoryId=" + categoryId,
    };
    mutationdelete.mutate(parms);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.category,
      sortable: true,
      cell: (row) => (
        <>
          <img
            className="table_img rounded-circle img-thumbnail me-2"
            loading="lazy"
            src={
              row?.categoryPic == null
                ? avatar1
                : Image_endpoint + row.categoryPic
            }
          />
          {row.category}
        </>
      ),
    },
    {
      name: "Date",
      selector: (row) => moment(row.createdAt).format("DD-MM-YY"),
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <>
          <Link className="btnview" to={`/Subcategory/${row?._id}`}>
            <Icon icon="tabler:eye"></Icon>
          </Link>
          <Link className="btnedit" onClick={() => handleShow2(row)}>
            <Icon icon="tabler:pencil"></Icon>
          </Link>
          <Link className="btndelete" onClick={() => handleDelete(row._id)}>
            <Icon icon="tabler:trash"></Icon>
          </Link>
        </>
      ),
    },
  ];

  const filteredData = categorylist?.data?.filter((item) =>
    item.category.toLowerCase().includes(searchText.toLowerCase())
  );
  const removeImageA = (val, key) => {
    const isBlob = val.startsWith("blob:");
    if (isBlob) {
      // Remove from local
      setEditForm((prev) => ({
        ...prev,
        categoryPic: "",
      }));
      //   document.getElementById('categoryimg').value = null;
    } else {
      // Call remove API
      authInstance
        .post(
          `${REACT_APP_API_URL}/deleteBusinessCertificatePic`,
          {
            fileName: val.split("/").pop(), // Extract the file name from the URL
            businessId: selectedCategoryId, // Replace with the actual business ID
            type: key,
          },
          {
            headers: {
              Authorization: `Bearer YOUR_BEARER_TOKEN`, // Replace with your actual Bearer Token
            },
          }
        )
        .then((response) => {
          console.log("API Response:", response);
          // Remove from local after successful API call
          queryClient.invalidateQueries("categorylist");

          setEditForm((prev) => ({
            ...prev,
            categoryPic: "",
          }));
          // document.getElementById('categoryimg')?.value = null;
        })
        .catch((error) => {
          console.error("API Error:", error);
        });
    }
  };
  const handleImage = (e) => {
    e.preventDefault();
    const file = e.target.files[0]; // Get the first selected file

    // Ensure a file is selected before updating state
    if (file) {
      setForm((prev) => ({
        ...prev,
        categoryPic: file,
      }));
    }
  };
  const handleEditImage = (e) => {
    e.preventDefault();
    let file = e.target.files[0];
    setEditForm((prev) => {
      let obj = { ...prev };
      obj.categoryPic = file;
      return obj;
    });
  };

  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between">
          <h5>Categories</h5>
          <Button
            className="btn btn-primary px-4 min_width140"
            onClick={handleShow}
          >
            Add
          </Button>
        </Card.Header>
        <Card.Body>
          <div className="text-end mb-3">
            <input
              type="text"
              placeholder="Search..."
              className="searchfield"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </div>
          <DataTable
            columns={columns}
            data={filteredData}
            pagination
            responsive
            className="custom-table"
          />
        </Card.Body>
      </Card>
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="modalhead">Add New Category</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type="text"
                value={form?.category}
                onChange={(e) => setForm({ ...form, category: e.target.value })}
                placeholder="Enter category name"
              />
              <p style={{ fontSize: 12, color: "red" }}>
                {errors.category && <span>{errors.category}</span>}
              </p>
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Image</Form.Label>
              <Form.Control
                onChange={handleImage}
                id="categoryimg"
                name="categoryimg"
                className="displaynone"
                type="file"
              />
              <Form.Label className="fileupload" htmlFor="categoryimg">
                <Icon className="uplaodicon" icon="ion:camera-outline"></Icon>
                <h3>Drag & Drop Here</h3>
              </Form.Label>
              {form && form?.categoryPic && (
                <>
                  <img
                    src={
                      form.categoryPic && URL.createObjectURL(form.categoryPic)
                    }
                    style={{
                      width: 60,
                      height: 70,
                      border: "1px solid black",
                      borderRadius: 10,
                      marginTop: 10,
                      cursor: "pointer",
                    }}
                  />
                  <span
                    onClick={removeImage}
                    style={{
                      color: "white",
                      position: "absolute",
                      marginLeft: "-4px",
                      border: "0.4px solid",
                      borderRadius: "180px",
                      background: "darkred",
                      fontWeight: "bold",
                      display: "inline-flex", // Ensures the icon is centered
                      alignItems: "center",
                      justifyContent: "center",
                      width: "20px", // Ensure the size of the container
                      height: "20px", // Ensure the size of the container
                      cursor: "pointer",
                    }}
                  >
                    <Icon icon="tabler:x" />
                  </span>
                </>
              )}
            </Form.Group>
          </Form>
          <Button onClick={handleSubmit} className="btn btn-primary px-4 w-100">
            Add
          </Button>
        </Modal.Body>
      </Modal>
      <Modal show={show2} onHide={handleClose2} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <h2 className="modalhead">Edit Category</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Category Name</Form.Label>
              <Form.Control
                type="text"
                value={editForm?.category}
                onChange={(e) =>
                  setEditForm({ ...editForm, category: e.target.value })
                }
                placeholder="Enter category name"
              />
              <p style={{ fontSize: 12, color: "red" }}>
                {errors.category && <span>{errors.category}</span>}
              </p>
            </Form.Group>
            <Form.Group className="mb-3 form-group">
              <Form.Label>Image</Form.Label>
              <Form.Control
                onChange={handleEditImage}
                id="editCategoryImg"
                name="editCategoryImg"
                className="displaynone"
                type="file"
              />
              <Form.Label className="fileupload" htmlFor="editCategoryImg">
                <Icon className="uplaodicon" icon="ion:camera-outline"></Icon>
                <h3>Drag & Drop Here</h3>
              </Form.Label>
              {editForm && editForm?.categoryPic && (
                <>
                  <img
                    src={
                      typeof editForm.categoryPic != "object"
                        ? Image_endpoint + editForm.categoryPic
                        : URL.createObjectURL(editForm?.categoryPic)
                    }
                    style={{
                      width: 60,
                      height: 70,
                      border: "1px solid black",
                      borderRadius: 10,
                      marginTop: 10,
                      cursor: "pointer",
                    }}
                  />
                  <span
                    onClick={() =>
                      removeImageA(
                        typeof editForm.categoryPic != "object"
                          ? Image_endpoint + editForm.categoryPic
                          : URL.createObjectURL(editForm?.categoryPic),
                        "categoryPic"
                      )
                    }
                    style={{
                      color: "white",
                      position: "absolute",
                      marginLeft: "-20px",
                      border: "0.4px solid",
                      borderRadius: "180px",
                      background: "darkred",
                      fontWeight: "bold",
                      display: "inline-flex", // Ensures the icon is centered
                      alignItems: "center",
                      justifyContent: "center",
                      width: "20px", // Ensure the size of the container
                      height: "20px", // Ensure the size of the container
                      cursor: "pointer",
                    }}
                  >
                    <Icon icon="tabler:x" />
                  </span>
                </>
              )}
            </Form.Group>
          </Form>
          {/* <Button onClick={handleEditSubmit} className="btn btn-primary px-4 w-100">
                        Update
                    </Button> */}

          <Button
            className="btn btn-primary px-4 w-100"
            onClick={handleEditSubmit}
          >
            {" "}
            {mutation.isPending ? (
              <ThreeCircles
                visible={true}
                height="30"
                width="100"
                color="white"
                ariaLabel="three-circles-loading"
                wrapperStyle={{}}
                wrapperClass=""
              />
            ) : (
              "Update"
            )}
          </Button>
        </Modal.Body>
      </Modal>
      <Toaster />
    </React.Fragment>
  );
}

import Joi from "joi";

const businessValidationSchema = Joi.object({
  businessLogo: Joi.any().required().messages({
    "any.required": "Business logo is required",
  }),
  businessName: Joi.string().required().messages({
    "string.empty": "Business name is required",
    "any.required": "Business name is required",
  }),
  businessPhoneNumber: Joi.number().required().messages({
    "number.empty": "Business phone number is required",
    "any.required": "Business phone number is required",
  }),
  countryCode: Joi.string().optional(),
  isOpen: Joi.boolean().optional(),
  businessDescription: Joi.any().required().messages({
    "any.required": "Description is required",
  }),
  plan: Joi.string().required().messages({
    "string.empty": "Plan is required",
    "any.required": "Plan is required",
  }),
  businessCertificate: Joi.any().required().messages({
    "any.required": "Business certificate  is required",
  }),
  // businessCategory: Joi.string().required().messages({
  //   "string.empty": "Business category is required",
  //   "any.required": "Business category is required",
  // }),
  businessCategory: Joi.string()
    .pattern(/^[0-9a-fA-F]{24}$/) // Regex to validate ObjectId
    .required()
    .messages({
      "string.pattern.base": "Business category must be a valid ObjectId",
      "string.empty": "Business category is required",
      "any.required": "Business category is required",
    }),
  // businessSubCategory: Joi.any().optional(),
  businessSubCategory: Joi.array()
    .required()
    .messages({
      "array.base": "Business sub category is required",
      "any.required": "Business sub category is required",
    }),
  businessHour: Joi.object().optional(),
  businessPic: Joi.any().optional(),
  businessPic: Joi.any().required().messages({
    "any.required": "Business pic is required",
  }),
  // businessLocation: Joi.array().optional(),
  businessLocation: Joi.array().required().messages({
    "any.required": "business Location is required",
  }),
  isOpen24Hours: Joi.boolean().optional(),
  times: Joi.any().optional(),
  profileStep: Joi.number().optional(),
  whatsappLink: Joi.string().allow("").uri().optional().messages({"string.uri": "Must be a valid link"}),
  telegramLink: Joi.string().allow("").uri().optional().messages({"string.uri": "Must be a valid link"}),
  instagramLink: Joi.string().allow("").uri().optional().messages({"string.uri": "Must be a valid link"}),
  fullName: Joi.string().required().messages({
    "string.empty": "Full name is required",
    "any.required": "Full name is required",
  }),
  email: Joi.string()
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      "string.empty": "Email is required",
      "any.required": "Email is required",
      "string.email": "Please enter a valid email address",
    }),
  phoneNumber: Joi.string().optional(),
  businessImage: Joi.any().allow(),
  password: Joi.any().required().messages({
    "any.required": "password is required",
  }),
  // locationName: Joi.any().allow(),
  locationName: Joi.object().required().messages({
    "object.base": "Location Name is required",
    "any.required": "An object is required",
  }),
});

export default businessValidationSchema;

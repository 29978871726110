import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Tab,
  Tabs,
  Form,
  Card,
  Button,
  Alert,
} from "react-bootstrap";
import Avatar from "../assets/img/userimg1.jpg";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getadminDetails } from "../axiosConfig/api";
import imageCompression from "browser-image-compression";
import useUpdateAdmin from "../axiosConfig/mutations";
import { Image_endpoint, REACT_APP_API_URL } from "../../local";
import toast, { Toaster } from "react-hot-toast";
import { ThreeCircles } from "react-loader-spinner";
import schemapassChange from "../formValidation/changePassword";
import { AuthPost } from "../axiosConfig/promise";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
export default function ProfileSettings() {
  let url = "adminProfile";

  const { data, error, isLoading } = useQuery({
    queryKey: ["admindetails", url],
    queryFn: () => getadminDetails(url),
  });
  useEffect(() => {
    setId(data?._id);
    setName(data?.fullName || "");
    setEmail(data?.email || "");
    setPhone(data?.phoneNumber || "");
    setPrvImg(data && data.profilePic ? Image_endpoint + data.profilePic : "");
  }, [data]);
  const [adminId, setId] = useState(data?._id);
  const [name, setName] = useState(data?.name || "");
  const [email, setEmail] = useState(data?.email || "");
  const [phone, setPhone] = useState(data?.phoneNumber || "");
  const [key, setKey] = useState("basicinfo");
  const [form, setform] = useState({});
  const [errors, setErrors] = useState({});
  const queryClient = useQueryClient();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordnew, setShowPasswordnew] = useState(false);
  const [showPasswordconfirm, setShowPasswordconfirm] = useState(false);
  const [previewImg, setPrvImg] = useState(
    data && data.profilePic ? Image_endpoint + data.profilePic : ""
  );
  const [sendingImg, sendIMg] = useState(null);
  const { mutate, isPending, isError, status } = useUpdateAdmin();
  useEffect(() => {
    if (isError) {
      toast.error("Failed to update admin. Please try again later.");
    } else if (status === "success") {
      toast.success("Admin updated successfully.");
    }
  }, [isError, status]);
  const mutation = useMutation({
    mutationFn: (params) => AuthPost(params.url, params?.form),
    onSuccess: (data) => {
      if (data.status) {
        toast.success("Password changed successfully!");
        queryClient.invalidateQueries("subcatglisting");
        setErrors({});
        return;
      }
      toast.error(data?.message);
    },
    onError: (error) => {
      console.error("Error:", error.message);
      toast.error(error.message);
    },
    retry: 3,
    retryDelay: 1000,
    mutationKey: "subcatglisting",
    onErrorThrow: true,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  // useEffect(()=>{

  // },[status])
  async function handleImageUpload(event) {
    try {
      const imageFile = event.target.files[0];

      if (!imageFile) {
        throw new Error("No file selected");
      }

      // Create a URL for the original image file and set it as the preview image
      const url = URL.createObjectURL(imageFile);
      // setprvImg(url);

      // Compression options
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      };

      // Compress the image file
      const compressedFile = await imageCompression(imageFile, options);

      // Optionally, you might want to create a URL for the compressed image and set it as the preview image
      const compressedUrl = URL.createObjectURL(compressedFile);
      setPrvImg(compressedUrl);
      sendIMg(compressedFile);
      // You can now upload the compressedFile to the server or perform other actions
      // await uploadToServer(compressedFile); // Your own logic
    } catch (error) {
      console.log(error, "error");
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let formdata = new FormData();
    formdata.append("fullName", name);
    formdata.append("email", email);
    formdata.append("phoneNumber", phone);
    formdata.append("profileFile", sendingImg);
    console.log(adminId, "adminIdadminId>>>>");
    let obj = {
      adminId,
      formdata,
    };

    mutate(obj);
  };

  const validate = (formData) => {
    const { error } = schemapassChange.validate(formData, {
      abortEarly: false,
    });
    if (!error) return {};
    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const handlePasschange = (e) => {
    e.preventDefault();
    const formErrors = validate(form);
    setErrors(formErrors);
    if (Object.keys(formErrors).length === 0) {
      const params = {
        url: "adminChangePassword",
        form: form,
      };
      mutation.mutate(params);
    }
  };
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const togglePasswordVisibilitynew = () => {
    setShowPasswordnew(!showPasswordnew);
  };
  const togglePasswordVisibilityconfirm = () => {
    setShowPasswordconfirm(!showPasswordconfirm);
  };
  return (
    <React.Fragment>
      <Card>
        <Card.Header className="py-4">
          <h5>Profile Settings</h5>
        </Card.Header>
        <Card.Body>
          <Tabs
            defaultActiveKey="basicinfo"
            id="uncontrolled-tab-example"
            className="customtabs mb-2"
            activeKey={key}
            onSelect={(k) => setKey(k)}
          >
            <Tab eventKey="basicinfo" title="Basic Info">
              <Row>
                <Col md={5} xl={4} className="view_profile mb-3 ">
                  <div className="user_info text-center">
                    <div className="d-flex align-items-center justify-content-center">
                      <img
                        src={previewImg ?? Avatar}
                        className="rounded-circle img-fluid profilepic"
                        alt=""
                      />
                    </div>
                    <label
                      htmlFor="uploadprofileimg"
                      className="d-inline-block mt_30 btn btn-primary-outline px-4 font-size-14 lh-40"
                    >
                      Choose Picture
                    </label>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageUpload}
                      id="uploadprofileimg"
                      className="d-none"
                    />
                  </div>
                </Col>
                <Col md={7} xl={8}>
                  <Form>
                    <Row>
                      <Col lg={6}>
                        <Form.Group className="form-group mb-3">
                          <Form.Label>Name</Form.Label>
                          <Form.Control
                            type="text"
                            placeholder="Enter name"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="form-group mb-3">
                          <Form.Label>Email Address</Form.Label>
                          <Form.Control
                            type="email"
                            placeholder="Enter email address"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group className="form-group mb-3">
                          <Form.Label>Phone No.</Form.Label>
                          <PhoneInput
                        // country={"us"}
                        value={`${data?.phoneNumber}`}
                        enableSearch
                        // onChange={handlePhoneChange}
                        onChange={(value) => {
                          setPhone(value);
                        }}
                        inputStyle={{ width: "100%" , paddingLeft:"50px" }}
                      />
                          {/* <Form.Control
                            type="text"
                            placeholder="Enter phone no."
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                          /> */}
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      onClick={handleSubmit}
                      className="btn btn-primary px-4 mt-3"
                    >
                      {" "}
                      {isPending ? (
                        <ThreeCircles
                          visible={true}
                          height="30"
                          width="100"
                          color="white"
                          ariaLabel="three-circles-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                        />
                      ) : (
                        "update"
                      )}
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="changepassword" title="Change Password">
              <Row>
                <Col md={8} xl={9}>
                  {error && <Alert variant="danger">{errors}</Alert>}

                  <Form>
                    <Row>
                      <Col lg={6}>
                        <Form.Group
                          className="form-group mb-3"
                          style={{ position: "relative", height: "100px" }}
                        >
                          <Form.Label>Current Password</Form.Label>
                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            name="currentPassword"
                            value={form?.currentPassword}
                            onChange={(e) =>
                              setform({
                                ...form,
                                currentPassword: e.target.value,
                              })
                            }
                            placeholder="Current Password"
                          />
                          <span
                            onClick={togglePasswordVisibility}
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                          >
                            {showPassword ? <FaEye /> : <FaEyeSlash />}
                          </span>
                          <p style={{ fontSize: 12, color: "red" }}>
                            {errors.currentPassword && (
                              <span>{errors.currentPassword}</span>
                            )}
                          </p>
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group
                          className="form-group mb-3"
                          style={{ position: "relative", height: "100px" }}
                        >
                          <Form.Label>New Password</Form.Label>
                          <Form.Control
                            type={showPasswordnew ? "text" : "password"}
                            name="newPassword"
                            // value={newPassword}
                            value={form?.newPassword}
                            onChange={(e) =>
                              setform({ ...form, newPassword: e.target.value })
                            }
                            placeholder="New Password"
                          />
                          <span
                            onClick={togglePasswordVisibilitynew}
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                          >
                            {showPasswordnew ? <FaEye /> : <FaEyeSlash />}
                          </span>
                          <p style={{ fontSize: 12, color: "red" }}>
                            {errors.newPassword && (
                              <span>{errors.newPassword}</span>
                            )}
                          </p>
                        </Form.Group>
                      </Col>
                      <Col lg={6}>
                        <Form.Group
                          className="form-group mb-3"
                          style={{ position: "relative", height: "100px" }}
                        >
                          <Form.Label>Confirm New Password</Form.Label>
                          <Form.Control
                            type={showPasswordconfirm ? "text" : "password"}
                            name="confirmPassword"
                            value={form?.confirmPassword}
                            onChange={(e) =>
                              setform({
                                ...form,
                                confirmPassword: e.target.value,
                              })
                            }
                            placeholder="Confirm New Password"
                          />
                           <span
                            onClick={togglePasswordVisibilityconfirm}
                            style={{
                              position: "absolute",
                              right: "10px",
                              top: "50%",
                              transform: "translateY(-50%)",
                              cursor: "pointer",
                            }}
                          >
                            {showPasswordconfirm ? <FaEye /> : <FaEyeSlash />}
                          </span>
                          <p style={{ fontSize: 12, color: "red" }}>
                            {errors.confirmPassword && (
                              <span>{errors.confirmPassword}</span>
                            )}
                          </p>
                        </Form.Group>
                      </Col>
                    </Row>
                    <Button
                      className="btn btn-primary px-4 mt-3"
                      onClick={handlePasschange}
                    >
                      Update
                    </Button>
                  </Form>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Card.Body>
      </Card>
      <Toaster />
    </React.Fragment>
  );
}

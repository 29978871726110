import React, { useState, useEffect } from "react";
import { Row, Col, Form, Card, Button } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Avatar from "../assets/img/userport.png";
import { AuthgetApi, authFormdataPost } from "../axiosConfig/promise";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Image_endpoint } from "../../local";
// import toast from "react-hot-toast";
import Cookies from "js-cookie";
import validationSchema from "../formValidation/addUserSchema";
import { ThreeCircles } from "react-loader-spinner";
import toast, { Toaster } from "react-hot-toast";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
export default function EditUser() {
  const [previewImage, setPreview] = useState(null);
  const [sendingImage, setImage] = useState(null);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const url = `admingetUser?userId=${id}`;
  const queryClient = useQueryClient();

  const {
    data: userinfo,
    error,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["userdetails", url],
    queryFn: () => AuthgetApi(url),
    staleTime: Infinity,
  });
  // Function to handle the refresh
  const handleRefresh = () => {
    refetch();
  };

  const [form, setForm] = useState({});

  useEffect(() => {
    handleRefresh();
    if (userinfo?.data) {
      // Filter out disallowed fields
      const allowedFields = ["fullName", "email", "phoneNumber", "password", "countryCode"];
      const filteredData = Object.keys(userinfo.data)
        .filter((key) => allowedFields.includes(key))
        .reduce((obj, key) => {
          obj[key] = userinfo.data[key];
          return obj;
        }, {});
        setForm({
          ...filteredData,
          phoneNumber: `${filteredData.countryCode}${filteredData.phoneNumber}`,
        });
        console.log("noq",form.phoneNumber);
        
      // setForm(...filteredData,phoneNumber:`${form.countryCode}${form.phoneNumber}`);
      // setForm({
      //   ...form,
      //   // countryCode, // Update countryCode
      //   phoneNumber:`${form.countryCode}${form.phoneNumber}`, // Update phoneNumber
      // });
    }
  }, [userinfo]);
  const convertToFormData = (form) => {
    const formData = new FormData();
    for (const key in form) {
      formData.append(key, form[key]);
    }
    return formData;
  };

  const mutation = useMutation({
    mutationFn: authFormdataPost,
    onSuccess: (data) => {
      if (data.status) {
        toast.success("Updated successfully!");
        queryClient.invalidateQueries("userListing"); // Invalidate and refetch admin data if you have a query for it
        setForm({});
        navigate(-1);
      } else {
        console.log("error", data);

        toast.error(data?.message);
      }
    },
    onError: (error) => {
      toast.error(error.message);
    },
    retry: 3,
    retryDelay: 1000,
    mutationKey: ["userListing", "userdetails"],
  });

  const handleImage = (event) => {
    const file = event.target.files[0];
    const previewURL = URL.createObjectURL(file);
    setPreview(previewURL);
    setImage(file);
  };

  const validate = () => {
    const { error } = validationSchema.validate(form, { abortEarly: false });
    if (!error) return {};
    const errors = {};
    for (let item of error.details) {
      errors[item.path[0]] = item.message;
    }
    return errors;
  };

  const handleSubmit = (e) => {
    
    e.preventDefault();
    const formErrors = validate();
    setErrors(formErrors);
    console.log("formErrors",errors);

    if (Object.keys(formErrors).length === 0) {
      const formData = convertToFormData({
        ...form,
        profilePic:
          sendingImage != null ? sendingImage : userinfo?.data?.profilePic,
        userId: id,
      });
      const params = {
        url: `editAdminUserProfile`,
        formdata: formData,
      };
    console.log("formData",formData);
    // return;
      mutation.mutate(params);
    }
  };

  return (
    <React.Fragment>
      <Card>
        <Card.Header className="d-flex align-items-center justify-content-between flex-wrap">
          <h5>Edit User</h5>
          <Link
            className="btn btn-primary d-inline-flex align-items-center px-4 min_width140"
            to="/Users"
          >
            Back
          </Link>
        </Card.Header>
        <Card.Body>
          <Row className="mt-3">
            <Col md={5} xl={4} className="view_profile mb-3">
              <div className="user_info text-center">
                <img
                  src={
                    previewImage
                      ? previewImage
                      : userinfo?.data?.profilePic
                      ? `${Image_endpoint}${userinfo.data.profilePic}`
                      : Avatar
                  }
                  className="rounded-circle img-fluid profilepic"
                  alt="Profile"
                />
                <label
                  htmlFor="uploadprofileimg"
                  className="d-inline-block mt_30 btn btn-primary-outline px-4 font-size-14 lh-40"
                >
                  Choose Picture
                </label>
                <input
                  type="file"
                  onChange={handleImage}
                  id="uploadprofileimg"
                  className="d-none"
                />
              </div>
            </Col>
            <Col md={7} xl={8}>
              <Form>
                <Row>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter name"
                        value={form.fullName || ""}
                        onChange={(e) =>
                          setForm({ ...form, fullName: e.target.value })
                        }
                        isInvalid={!!errors.fullName}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.fullName}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter email address"
                        value={form.email || ""}
                        onChange={(e) =>
                          setForm({ ...form, email: e.target.value })
                        }
                        isInvalid={!!errors.email}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group mb-3">
                      <Form.Label>Phone No.</Form.Label>
                      <PhoneInput
                        // country={"us"}
                        value={`${form?.phoneNumber}`}
                        enableSearch
                        // onChange={handlePhoneChange}
                        onChange={(value) => {
                          // const countryCode = value.slice(0, 1); // Extract country code (or adjust based on your needs)
                          const phoneNumber = value; // Extract the phone number (after country code)
                      
                          setForm({
                            ...form,
                            // countryCode, // Update countryCode
                            phoneNumber, // Update phoneNumber
                          });
                        }}
                        inputStyle={{ width: "100%" , paddingLeft:"50px" }}
                      />
                      {/* <Form.Control
                        type="text"
                        placeholder="Enter phone no."
                        value={form.phoneNumber || ""}
                        onChange={(e) =>
                          setForm({ ...form, phoneNumber: e.target.value })
                        }
                        isInvalid={!!errors.phoneNumber}
                      /> */}
                      <Form.Control.Feedback type="invalid">
                        {errors.phoneNumber}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
                <Button
                  type="submit"
                  onClick={handleSubmit}
                  className="btn btn-primary px-4 mt-3 min_width140"
                >
                  {mutation.isPending ? (
                    <ThreeCircles
                      visible={true}
                      height="30"
                      width="100"
                      color="white"
                      ariaLabel="three-circles-loading"
                      wrapperStyle={{}}
                      wrapperClass=""
                    />
                  ) : (
                    "Update"
                  )}
                </Button>
              </Form>
            </Col>
          </Row>
          <Toaster />
        </Card.Body>
      </Card>
    </React.Fragment>
  );
}
